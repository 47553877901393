import { useEffect, useRef, useState, useCallback } from 'react';
import { createPortal } from 'react-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { usePostHog } from 'posthog-js/react';
import Refiner from 'refiner-js';
import { AnimatePresence } from 'framer-motion';
import validator from 'validator';
import parse from 'html-react-parser';
import _ from 'lodash';
import cx from 'classnames';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

import Fade from 'components/ui/Fade';
import SwitchButton, { ActiveSide } from 'components/ui/SwitchButton';
import Select from 'components/ui/Select';
import Loading from 'components/Loading/Loading';
import Modal, { Props as ModalProps } from 'components/ui/Modal';
import ResultPane from 'components/ui/ResultPane';
import Input from 'components/ui/Input';
import Headline from 'components/ui/Headline';
import PhoneVerification from 'components/PhoneVerification';
import FinalView from 'components/FinalView';

import { useCmsData, Translations } from 'contexts/CmsDataProvider';
import {
  getAttributes,
  isBlacklisted,
  getMakes,
  getModels,
  getVehicleByLicensePlate,
  getVehicles,
  makeSubmission,
  sendOtpCode,
  verifyPhoneNumber,
  isDuplicatedSubmission,
} from 'api/api';
import { IS_PRODUCTION, LS_MAKES_DATA_CACHE, MAKES } from 'constants/constants';
import {
  focusNextField,
  formatPhoneNumber,
  formatRelevantVehicles,
  getOptionsFromAttributes,
  getRelevantOptions,
  parseNumber,
  parseUniqueByField,
  scrollToBottom,
  scrollToTop,
} from 'utils/utils';
import { trackEvent } from 'services/plausible';
import { Stage } from 'components/StatusLine';

import { Mode, Step, AuctionStatus } from 'enums/enums';
import type {
  SubmissionData,
  SubmittedFormData,
  UserInput,
  VehicleResults,
} from 'types/types';

type Props = {
  onSetStage: (stage: Stage) => any;
  mode: Mode;
  setMode: (mode: Mode) => void;
  step: Step;
  setStep: (step: Step) => void;
};

const yearOptions = _.range(new Date().getFullYear(), 1969, -1).map((year) => ({
  name: year.toString(),
  value: year,
}));

const INITIAL_DATA: UserInput = {
  make: null,
  model: null,
  year: null,
  bodyType: null,
  fuel: null,
  gear: null,
  type: null,
  articleKey: null,
  mileage: null,
  condition: null,
  equipment: null,
  email: null,
  firstName: null,
  lastName: null,
  phone: null,
  token: null,
};

const orderedFields = [
  ..._.filter(
    _.map(
      _.keys(Step).filter((key) => !isNaN(Number(key))),
      (key: any) => _.camelCase(Step[key])
    ),
    (key) => _.has(INITIAL_DATA, key)
  ),
  ..._.difference(
    _.keys(INITIAL_DATA),
    _.filter(
      _.map(
        _.keys(Step).filter((key) => !isNaN(Number(key))),
        (key: any) => _.camelCase(Step[key])
      ),
      (key) => _.has(INITIAL_DATA, key)
    )
  ),
];

const INITIAL_VEHICLE_RESULTS: VehicleResults = {
  all: [],
  bodyTypes: [],
  fuels: [],
  gears: [],
  types: [],
};

const INITAL_MODAL_DATA = {
  isOpen: false,
  title: '',
  description: '',
  btnText: '',
  onConfirm: () => {},
};
const NO_VEHICLES_MODAL = (t: Translations['t']) => ({
  isOpen: true,
  title: t('generalTextStrings', 'error_no_vehicle_modal_title'),
  description: t('generalTextStrings', 'error_no_vehicle_modal_description'),
  btnText: t('generalTextStrings', 'error_no_vehicle_modal_button'),
  onConfirm: () => {},
});

const DUPLICATE_SUBMISSION_IN_REVIEW_MODAL = (
  t: Translations['t'],
  brand: string,
  model: string
) => ({
  isOpen: true,
  title: t(
    'generalTextStrings',
    'error_duplicate_submission_in_review_modal_title'
  ),
  description: (
    <ReactMarkdown
      className="markdown"
      rehypePlugins={[rehypeRaw]}
      children={t(
        'generalTextStrings',
        'error_duplicate_submission_in_review_modal_description',
        '',
        {
          brand: brand,
          model: model,
        }
      )}
    />
  ),
  btnText: t(
    'generalTextStrings',
    'error_duplicate_submission_in_review_modal_button'
  ),
  onConfirm: () => {},
});

const DUPLICATE_SUBMISSION_SCHEDULED_MODAL = (
  t: Translations['t'],
  brand: string,
  model: string
) => ({
  isOpen: true,
  title: t(
    'generalTextStrings',
    'error_duplicate_submission_scheduled_modal_title'
  ),
  description: (
    <ReactMarkdown
      className="markdown"
      rehypePlugins={[rehypeRaw]}
      children={t(
        'generalTextStrings',
        'error_duplicate_submission_scheduled_modal_description',
        '',
        {
          brand: brand,
          model: model,
        }
      )}
    />
  ),
  btnText: t(
    'generalTextStrings',
    'error_duplicate_submission_scheduled_modal_button'
  ),
  onConfirm: () => {},
});

const DUPLICATE_SUBMISSION_ACTIVE_MODAL = (
  t: Translations['t'],
  brand: string,
  model: string
) => ({
  isOpen: true,
  title: t(
    'generalTextStrings',
    'error_duplicate_submission_active_modal_title'
  ),
  description: (
    <ReactMarkdown
      className="markdown"
      rehypePlugins={[rehypeRaw]}
      children={t(
        'generalTextStrings',
        'error_duplicate_submission_active_modal_description',
        '',
        {
          brand: brand,
          model: model,
        }
      )}
    />
  ),
  btnText: t(
    'generalTextStrings',
    'error_duplicate_submission_active_modal_button'
  ),
  onConfirm: () => {},
});

const BLACKLIST_MODAL = (t: Translations['t']) => ({
  isOpen: true,
  title: t('generalTextStrings', 'error_blacklist_modal_title'),
  description: (
    <ReactMarkdown
      className="markdown"
      rehypePlugins={[rehypeRaw]}
      children={t('generalTextStrings', 'error_blacklist_modal_description')}
    />
  ),
  btnText: t('generalTextStrings', 'error_blacklist_modal_button'),
  onConfirm: () => {},
});

const NO_LICENSE_PLATE_RESULTS_MODAL = (t: Translations['t']) => ({
  isOpen: true,
  title: t('generalTextStrings', 'error_no_license_plate_number_modal_title'),
  description: t(
    'generalTextStrings',
    'error_no_license_plate_number_modal_description'
  ),
  btnText: t(
    'generalTextStrings',
    'error_no_license_plate_number_modal_button'
  ),
  onConfirm: () => {},
});
const ESTIMATION_PRICE_DESC_MODAL = (t: Translations['t']) => ({
  isOpen: true,
  title: t('directsaleTextStrings', 'estimation_price_desc_modal_title'),
  description: parse(
    t('directsaleTextStrings', 'estimation_price_desc_modal_description')
  ),
  btnText: t('directsaleTextStrings', 'estimation_price_desc_modal_button'),
  onConfirm: () => {},
});

const NO_ESTIMATION_MODAL = (t: Translations['t']) => ({
  isOpen: true,
  title: t('directsaleTextStrings', 'no_estimation_modal_title'),
  description: (
    <ReactMarkdown
      className="markdown"
      rehypePlugins={[rehypeRaw]}
      children={t('directsaleTextStrings', 'no_estimation_modal_description')}
    />
  ),
  btnText: t('directsaleTextStrings', 'no_estimation_modal_button'),
  onConfirm: () => {},
});

const selectCls = 'mb-6 last:mb-[240px]';

const Main: React.FC<Props> = ({
  onSetStage,
  step,
  setStep,
  mode,
  setMode,
}) => {
  const [userInput, setUserInput] = useState<UserInput>(INITIAL_DATA);
  const [vehicleResults, setVehicleResults] = useState(INITIAL_VEHICLE_RESULTS);
  const [selectedVehicle, setSelectedVehicle] = useState<
    VehicleResults['all'][number] | null
  >(null);
  const [estimationPrice, setEstimatedPrice] = useState<{
    min: number;
    max: number;
  } | null>(null);
  const [submittedData, setSubmittedData] = useState<SubmittedFormData | null>(
    null
  );
  const {
    make,
    model,
    year,
    bodyType,
    fuel,
    gear,
    type,
    articleKey,
    mileage,
    condition,
    equipment,
    email,
    firstName,
    lastName,
    phone,
    token,
  } = userInput;
  const [modal, setModal] = useState<
    { isOpen: boolean } & Omit<ModalProps, 'onDismiss'>
  >(INITAL_MODAL_DATA);
  const [submissionLink, setSubmissionLink] = useState<string | null>(null);

  const queryClient = useQueryClient();
  const {
    id: widgetId,
    data: cmsData,
    t,
    locale,
    getFormFieldType,
    getGeneralOptions,
  } = useCmsData();
  const posthog = usePostHog();

  const prevStepRef = useRef(step);
  const modelRef = useRef<HTMLDivElement>(null);
  const yearRef = useRef<HTMLDivElement>(null);
  const bodyTypeRef = useRef<HTMLDivElement>(null);
  const fuelRef = useRef<HTMLDivElement>(null);
  const gearRef = useRef<HTMLDivElement>(null);
  const typeRef = useRef<HTMLDivElement>(null);
  const articleKeyRef = useRef<HTMLDivElement>(null);
  const conditionSectionRef = useRef<HTMLHeadingElement>(null);
  const mileageRef = useRef<HTMLInputElement>(null);
  const conditionRef = useRef<HTMLDivElement>(null);
  const equipmentRef = useRef<HTMLDivElement>(null);
  const offerSectionRef = useRef<HTMLHeadingElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);
  const firstNameRef = useRef<HTMLInputElement>(null);
  const lastNameRef = useRef<HTMLInputElement>(null);
  const phoneRef = useRef<HTMLInputElement>(null);
  const otpCodeRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setStep(mode === Mode.LicensePlate ? Step.LicensePlate : Step.Make);
  }, [mode, setStep]);

  useEffect(() => {
    if (step === Step.FinalView) {
      document
        .getElementById('container')
        ?.scrollTo({ top: 0, behavior: 'smooth' });
    } else if (step > Step.Model) {
      if (prevStepRef.current !== Step.FinalView) {
        onFocusNextField(step);
      }
    }

    prevStepRef.current = step;
  }, [step]); // eslint-disable-line

  const handleScroll = useCallback(
    (e: any) => {
      const scrollTop = e.target.scrollTop;
      const threshold = 30;

      const conditionSectionTop =
        conditionSectionRef.current &&
        conditionSectionRef.current.getBoundingClientRect().y - 430;
      const offerSectionTop =
        offerSectionRef.current &&
        offerSectionRef.current.getBoundingClientRect().y;

      if (step === Step.FinalView) {
        onSetStage(Stage.Completed);
      } else if (offerSectionTop && scrollTop >= offerSectionTop) {
        onSetStage(Stage.Offer);
      } else if (
        conditionSectionTop &&
        (scrollTop >= conditionSectionTop + threshold ||
          (scrollTop > 30 &&
            scrollTop < 240 &&
            conditionSectionTop < 240 &&
            step === Step.Mileage))
      ) {
        onSetStage(Stage.Condition);
      } else if (
        !scrollTop ||
        (conditionSectionTop && scrollTop < conditionSectionTop - threshold)
      ) {
        onSetStage(Stage.Search);
      }
    },
    [onSetStage, step, conditionSectionRef, offerSectionRef]
  );

  useEffect(() => {
    const containerElement = document.getElementById('container');

    if (!containerElement) return;

    const debouncedScroll = _.debounce(handleScroll, 8);

    containerElement.addEventListener('scroll', debouncedScroll);

    return () => {
      containerElement.removeEventListener('scroll', debouncedScroll);
      debouncedScroll.cancel();
    };
  }, [handleScroll]);

  const onFocusNextField = (step: number) => {
    switch (step) {
      case Step.Model:
        return focusNextField(modelRef);
      case Step.Year: {
        return prevStepRef.current < Step.Year && focusNextField(yearRef);
      }
      case Step.BodyType: {
        return focusNextField(bodyTypeRef);
      }
      case Step.Fuel: {
        return focusNextField(fuelRef);
      }
      case Step.Gear: {
        return focusNextField(gearRef);
      }
      case Step.Type: {
        return focusNextField(typeRef);
      }
      case Step.ArticleKey: {
        return focusNextField(articleKeyRef);
      }
      case Step.Mileage: {
        focusNextField(mileageRef, 'input');
        return setTimeout(scrollToBottom, 300);
      }
      case Step.Condition: {
        return focusNextField(conditionRef);
      }
      case Step.Equipment: {
        return focusNextField(equipmentRef);
      }
      case Step.Email: {
        focusNextField(emailRef, 'input');
        return setTimeout(scrollToBottom, 300);
      }
      case Step.FirstName: {
        return focusNextField(firstNameRef, 'input');
      }
      case Step.LastName: {
        return focusNextField(lastNameRef, 'input');
      }
      case Step.Phone: {
        focusNextField(phoneRef, 'input');
        return setTimeout(scrollToBottom, 300);
      }
      case Step.PhoneVerification: {
        focusNextField(otpCodeRef, 'input');
        return setTimeout(scrollToBottom, 300);
      }
      default:
        break;
    }
  };

  const {
    data: makes = localStorage.getItem(LS_MAKES_DATA_CACHE)
      ? JSON.parse(localStorage.getItem(LS_MAKES_DATA_CACHE)!)
      : [],
  } = useQuery('makes', () => getMakes(), {
    enabled: !queryClient.getQueryData('makes'),
    onError: () =>
      alert(
        'Unfortunately something went wrong! Please try again later or contact us.'
      ),
  });

  const { data: models = [], isLoading: isLoadingModels } = useQuery(
    ['models', make],
    () => getModels(make),
    {
      enabled: !!make && !queryClient.getQueryData(['models', make]),
      onSuccess: () => focusNextField(modelRef),
      onError: () =>
        alert(
          'Unfortunately something went wrong! Please try again later or contact us.'
        ),
    }
  );
  const { isLoading: isLoadingAttributes, data: attributes } = useQuery(
    'attributes',
    () => getAttributes(),
    {
      onError: () =>
        alert(
          'Unfortunately something went wrong! Please try again later or contact us.'
        ),
    }
  );

  const { mutateAsync: onIsBlacklisted, isLoading: isLoadingIsBlacklisted } =
    useMutation(
      'blacklist',
      ({
        email,
        phone,
      }: {
        email: UserInput['email'];
        phone: UserInput['phone'];
      }) => isBlacklisted(email, phone),
      {
        onError: () =>
          alert(
            'Unfortunately something went wrong! Please try again later or contact us.'
          ),
      }
    );
  const {
    mutateAsync: onIsDuplicatedSubmission,
    isLoading: isLoadingIsDuplicatedSubmission,
  } = useMutation(
    'duplicatedSubmission',
    ({
      email,
      phone,
      make,
      model,
    }: {
      email: UserInput['email'];
      phone: UserInput['phone'];
      make: UserInput['make'];
      model: UserInput['model'];
    }) => isDuplicatedSubmission(email, phone, make, model),
    {
      onError: () =>
        alert(
          'Unfortunately something went wrong! Please try again later or contact us.'
        ),
    }
  );
  const { mutate: onGetVehicles, isLoading: isLoadingVehicles } = useMutation(
    'vehicles',
    ({ make, model, year }: Pick<UserInput, 'make' | 'model' | 'year'>) =>
      getVehicles({
        make,
        model,
        year,
      }),
    {
      retry: 3,
      onSuccess: (data: any) => {
        if (!data || !data.length) {
          return setModal({
            ...NO_VEHICLES_MODAL(t),
            onConfirm: () => setModal((prev) => ({ ...prev, isOpen: false })),
          });
        }

        setVehicleResults({
          all: data,
          bodyTypes: parseUniqueByField(data, 'bodyType'),
          fuels: parseUniqueByField(data, 'fuel'),
          gears: parseUniqueByField(data, 'gear'),
          types: parseUniqueByField(data, 'type'),
        });
        setStep(Step.BodyType);
      },
      onError: () => {
        setStep(Step.Year);
        setVehicleResults(INITIAL_VEHICLE_RESULTS);
        alert(
          'Unfortunately something went wrong! Please try again later or contact us.'
        );
      },
    }
  );
  const {
    mutate: onGetVehicleByLicensePlate,
    isLoading: isLoadingGetVehicleByLicensePlate,
  } = useMutation(
    'getVehicleByLicensePlate',
    (licensePlate: string) => getVehicleByLicensePlate(licensePlate),
    {
      retry: 3,
      onSuccess: (data) => {
        if (!data || !data.length || !data[0].bodyType) {
          throw Error();
        }

        setVehicleResults((prev) => ({ ...prev, all: data }));

        if (data.length > 1) {
          return setStep(Step.ArticleKey);
        }

        onChangeResultPane(data[0].articleKey, data);
      },
      onError: () =>
        setModal({
          ...NO_LICENSE_PLATE_RESULTS_MODAL(t),
          onConfirm: () => {
            setModal((prev) => ({ ...prev, isOpen: false }));
            setMode(
              mode === Mode.LicensePlate ? Mode.Brand : Mode.LicensePlate
            );
          },
        }),
    }
  );

  const { mutateAsync: onSendOtpCode, isLoading: isLoadingSendOtpCode } =
    useMutation('sendOtp', (phone: UserInput['phone']) => sendOtpCode(phone), {
      onSuccess: () => setStep(Step.PhoneVerification),
      onError: () =>
        alert(
          'Unfortunately something went wrong! Please try again later or contact us.'
        ),
    });

  const {
    mutateAsync: onVerifyPhoneNumber,
    isLoading: isLoadingVerifyPhoneNumber,
  } = useMutation('verifyOtp', (code: string) =>
    verifyPhoneNumber({ phone, code })
  );
  const { mutateAsync: onMakeSubmission, isLoading: isLoadingMakeSubmission } =
    useMutation('makeSubmission', (data: any) => makeSubmission(data));

  const handleChangeUserInput = (field: keyof UserInput, value: any) => {
    const fieldIndex = orderedFields.indexOf(field as string);
    const updatedData = { ...userInput };

    orderedFields.forEach((key, index) => {
      if (index >= fieldIndex) {
        updatedData[key as keyof UserInput] =
          index === fieldIndex ? value : INITIAL_DATA[key as keyof UserInput];
      }
    });

    setUserInput(updatedData);
  };

  const onSubmit = async ({
    userPhone = '',
    verificationToken = '',
  }: {
    userPhone?: UserInput['phone'];
    verificationToken?: UserInput['token'];
  }) => {
    try {
      const data: SubmissionData = {
        widget_key: widgetId,
        token: verificationToken || token || '',
        type: 'sell',
        sell_option: 'fixprice',
        vehicle: {
          make:
            make ||
            _.findKey(MAKES, (val) => val === selectedVehicle?.brand) ||
            '',
          model: model || selectedVehicle?.model || '',
          first_registration_year:
            year ||
            parseInt(
              selectedVehicle?.firstRegistrationDate.split('.').pop() || ''
            ),
          first_registration_month:
            Number.parseInt(
              selectedVehicle?.firstRegistrationDate.split('.')[1] || '1'
            ) - 1,
          body: bodyType || selectedVehicle?.bodyType || '',
          fuel: fuel || selectedVehicle?.fuel || '',
          gear: gear || selectedVehicle?.gear || '',
          exact_model: type || selectedVehicle?.type || '',
          article_key: articleKey,
          hp: selectedVehicle?.hp || undefined,
          kw: selectedVehicle?.kw
            ? selectedVehicle?.kw
            : selectedVehicle?.hp
              ? Math.round(0.7457 * selectedVehicle.hp)
              : undefined,
          mileage,
          condition: condition || '',
          equipment: equipment || '',
          displacement: selectedVehicle?.displacement,
          number_of_doors: selectedVehicle?.doors,
          number_of_seats: selectedVehicle?.seats,
          wheel_drive: selectedVehicle?.wheelDrive || '',
        },
        contact: {
          first_name: firstName || '',
          last_name: lastName || '',
          email: email || '',
          phone: userPhone || phone || '',
          language: locale,
        },
        type_number: selectedVehicle?.typeNumber || '',
        price_from: estimationPrice ? estimationPrice.min : null,
        price_to: estimationPrice ? estimationPrice.max : null,
        widget: 'default',
        domain:
          new URLSearchParams(window.location.search).get('domain') ||
          window.location.origin,
        referrer: 'as24',
      };

      if (
        _.isEqual(submittedData, {
          vehicle: data.vehicle,
          contact: data.contact,
          type_number: data.type_number,
        })
      ) {
        setStep(Step.FinalView);
      } else {
        const { submission_id, estimation_price } =
          await onMakeSubmission(data);

        if (!submission_id) {
          throw new Error('No submission_id');
        }

        let domain = '';

        if (data.brand && data.brand.customized_upload_area_domain) {
          domain = data.brand.customized_upload_area_domain;

          if (domain.slice(-1) === '/') {
            domain = domain.slice(0, -1);
          }
        } else {
          domain = process.env.REACT_APP_ULOAD_AREA_DOMAIN!;
        }

        setEstimatedPrice(estimation_price);
        setSubmissionLink(`${domain}/u/${submission_id}?lang=${locale}`);
        setSubmittedData({
          vehicle: data.vehicle,
          contact: data.contact,
          type_number: data.type_number,
        });
        setStep(Step.FinalView);
        trackEvent('as24-step2-price-valuation');

        if (IS_PRODUCTION) {
          Refiner('identifyUser', {
            id: submission_id,
            email,
            name: `${firstName} ${lastName}`,
          });
        }
      }
    } catch (err: any) {
      alert(
        'Unfortunately something went wrong! Please try again later or contact us.'
      );
    }
  };

  const onChangeResultPane = (
    articleKey: UserInput['articleKey'],
    allVehicles?: VehicleResults['all'],
    silentUpdate = false
  ) => {
    trackEvent('as24-step1-vehicle-selection');
    silentUpdate
      ? setUserInput((prev) => ({ ...prev, articleKey }))
      : handleChangeUserInput('articleKey', articleKey);
    setSelectedVehicle(
      (allVehicles ? allVehicles : vehicleResults.all).find(
        (el) => el.articleKey === articleKey
      ) || null
    );

    if (!silentUpdate) {
      setStep(Step.Mileage);
      onFocusNextField(Step.Mileage);
    }
  };

  const onPhoneSubmit = async (value: string) => {
    const userPhone = formatPhoneNumber(value);
    handleChangeUserInput('phone', userPhone);
    posthog.capture('phone_entered');

    const blacklisted = await onIsBlacklisted({
      email: email,
      phone: userPhone,
    });
    const duplicated = await onIsDuplicatedSubmission({
      email: email,
      phone: userPhone,
      make:
        make || _.findKey(MAKES, (val) => val === selectedVehicle?.brand) || '',
      model: model || selectedVehicle?.model || '',
    });

    if (blacklisted) {
      setModal({
        ...BLACKLIST_MODAL(t),
        onConfirm: () => setModal((prev) => ({ ...prev, isOpen: false })),
      });
    } else if (duplicated.existingSubmission) {
      const brandName = makes.find(
        (make: { name: string; value: string }) =>
          duplicated.existingSubmission?.make === make.value
      );
      const status = duplicated.existingSubmission.status;
      const modalFunc =
        status === AuctionStatus.InReview
          ? DUPLICATE_SUBMISSION_IN_REVIEW_MODAL
          : status === AuctionStatus.Scheduled
            ? DUPLICATE_SUBMISSION_SCHEDULED_MODAL
            : status === AuctionStatus.Active
              ? DUPLICATE_SUBMISSION_ACTIVE_MODAL
              : null;

      if (modalFunc) {
        setModal({
          ...modalFunc(t, brandName.name, duplicated.existingSubmission.model),
          onConfirm: () => setModal((prev) => ({ ...prev, isOpen: false })),
        });
      }
    } else {
      if (cmsData?.enable2fa) {
        onSendOtpCode(userPhone);
      } else {
        onSubmit({ userPhone });
      }
    }
  };

  const modalElement = createPortal(
    <AnimatePresence>
      {modal.isOpen && (
        <Fade>
          <Modal
            {...modal}
            onDismiss={() => setModal((prev) => ({ ...prev, isOpen: false }))}
          />
        </Fade>
      )}
    </AnimatePresence>,
    document.body
  );

  if (step >= Step.FinalView && userInput && selectedVehicle) {
    return (
      <>
        {modalElement}
        <Fade>
          <FinalView
            vehicle={selectedVehicle}
            attributes={attributes}
            estimationPrice={estimationPrice}
            onEdit={() => {
              setStep(Step.Phone);
              scrollToTop();
            }}
            showEstimationPriceDescModal={() =>
              setModal({
                ...ESTIMATION_PRICE_DESC_MODAL(t),
                onConfirm: () =>
                  setModal((prev) => ({ ...prev, isOpen: false })),
              })
            }
            showNoEstimationModal={() =>
              setModal({
                ...NO_ESTIMATION_MODAL(t),
                onConfirm: () =>
                  setModal((prev) => ({ ...prev, isOpen: false })),
              })
            }
            submissionLink={submissionLink || ''}
          />
        </Fade>
      </>
    );
  }

  return (
    <>
      {modalElement}
      {createPortal(
        <AnimatePresence>
          {(isLoadingModels ||
            isLoadingVehicles ||
            isLoadingGetVehicleByLicensePlate ||
            isLoadingIsBlacklisted ||
            isLoadingIsDuplicatedSubmission ||
            isLoadingSendOtpCode ||
            isLoadingVerifyPhoneNumber ||
            isLoadingMakeSubmission ||
            (isLoadingAttributes && step >= Step.BodyType)) && (
            <Fade>
              <Loading />
            </Fade>
          )}
        </AnimatePresence>,
        document.body
      )}
      <div className="px-6">
        <Headline
          className="mb-6"
          content={t('directsaleTextStrings', 'search_title')}
        />
        <SwitchButton
          className="mb-6"
          activeSide={
            mode === Mode.LicensePlate ? ActiveSide.Left : ActiveSide.Right
          }
          leftText={t(
            'generalTextStrings',
            'search_type_option_license_plate_number'
          )}
          rightText={t('generalTextStrings', 'search_type_option_brand_model')}
          onLeftSwitch={() => setMode(Mode.LicensePlate)}
          onRightSwitch={() => setMode(Mode.Brand)}
        />

        {mode === Mode.Brand ? (
          <>
            <Fade className={selectCls}>
              <Select
                label={t('formFields', 'make', 'label')}
                options={makes}
                value={make}
                onChange={(make) => {
                  handleChangeUserInput('make', make);
                  setStep(Step.Model);
                  posthog.capture('brand_selected');

                  if (queryClient.getQueryData(['models', make])) {
                    onFocusNextField(Step.Model);
                  }
                }}
                placeholder={t('formFields', 'make', 'placeholder')}
                hint={t('formFields', 'make', 'hint')}
                tooltip={t('formFields', 'make', 'tooltip')}
              />
            </Fade>

            {step >= Step.Model && (
              <Fade className={selectCls}>
                <Select
                  ref={modelRef}
                  label={t('formFields', 'model', 'label')}
                  options={models}
                  value={model}
                  onChange={(model) => {
                    handleChangeUserInput('model', model);
                    setStep(Step.Year);
                    posthog.capture('model_selected', { brand: make, model });
                  }}
                  placeholder={t('formFields', 'model', 'placeholder')}
                  hint={t('formFields', 'model', 'hint')}
                  tooltip={t('formFields', 'model', 'tooltip')}
                />
              </Fade>
            )}

            {step >= Step.Year && (
              <Fade className={selectCls}>
                <Select
                  ref={yearRef}
                  label={t('formFields', 'first_registration_year', 'label')}
                  options={yearOptions}
                  value={year}
                  onChange={(year) => {
                    step > Step.Year && setStep(Step.Year);
                    handleChangeUserInput('year', year);
                    onGetVehicles({ make, model, year: year as number });
                  }}
                  placeholder={t(
                    'formFields',
                    'first_registration_year',
                    'placeholder'
                  )}
                  hint={t('formFields', 'first_registration_year', 'hint')}
                  tooltip={t(
                    'formFields',
                    'first_registration_year',
                    'tooltip'
                  )}
                />
              </Fade>
            )}

            {step >= Step.BodyType && (
              <Fade className={selectCls}>
                <Select
                  ref={bodyTypeRef}
                  label={t('formFields', 'body', 'label')}
                  options={getOptionsFromAttributes(
                    'bodyType',
                    vehicleResults.bodyTypes,
                    locale,
                    attributes
                  )}
                  value={bodyType}
                  onChange={(bodyType) => {
                    handleChangeUserInput('bodyType', bodyType);
                    setStep(Step.Fuel);
                  }}
                  placeholder={t('formFields', 'body', 'placeholder')}
                  hint={t('formFields', 'body', 'hint')}
                  tooltip={t('formFields', 'body', 'tooltip')}
                />
              </Fade>
            )}

            {step >= Step.Fuel && (
              <Fade className={selectCls}>
                <Select
                  ref={fuelRef}
                  label={t('formFields', 'fuel', 'label')}
                  options={getOptionsFromAttributes(
                    'fuel',
                    getRelevantOptions('fuel', vehicleResults.all, {
                      bodyType,
                    }),
                    locale,
                    attributes
                  )}
                  value={fuel}
                  onChange={(fuel) => {
                    handleChangeUserInput('fuel', fuel);
                    setStep(Step.Gear);
                  }}
                  placeholder={t('formFields', 'fuel', 'placeholder')}
                  hint={t('formFields', 'fuel', 'hint')}
                  tooltip={t('formFields', 'fuel', 'tooltip')}
                />
              </Fade>
            )}

            {step >= Step.Gear && (
              <Fade className={selectCls}>
                <Select
                  ref={gearRef}
                  label={t('formFields', 'gear', 'label')}
                  options={getOptionsFromAttributes(
                    'gear',
                    getRelevantOptions('gear', vehicleResults.all, {
                      bodyType,
                      fuel,
                    }),
                    locale,
                    attributes
                  )}
                  value={gear}
                  onChange={(gear) => {
                    handleChangeUserInput('gear', gear);
                    setStep(Step.Type);
                  }}
                  placeholder={t('formFields', 'gear', 'placeholder')}
                  hint={t('formFields', 'gear', 'hint')}
                  tooltip={t('formFields', 'gear', 'tooltip')}
                />
              </Fade>
            )}

            {step >= Step.Type && (
              <Fade className={cx(selectCls, { '!mb-6': step > Step.Type })}>
                <Select
                  ref={typeRef}
                  label={t('formFields', 'exact_model', 'label')}
                  options={getOptionsFromAttributes(
                    'type',
                    getRelevantOptions('type', vehicleResults.all, {
                      bodyType,
                      fuel,
                      gear,
                    }),
                    locale,
                    attributes
                  )}
                  value={type}
                  onChange={(type) => {
                    if (step === Step.Type) {
                      handleChangeUserInput('type', type);
                    } else {
                      setUserInput((prev) => ({
                        ...prev,
                        type: type as UserInput['type'],
                      }));
                    }

                    const relevantVehicles = formatRelevantVehicles(
                      vehicleResults.all,
                      { bodyType, fuel, gear, type },
                      locale,
                      attributes,
                      t
                    );

                    if (relevantVehicles.length === 1) {
                      onChangeResultPane(
                        relevantVehicles[0].articleKey,
                        vehicleResults.all,
                        true
                      );
                      if (step === Step.Type) {
                        setStep(Step.Mileage);
                        onFocusNextField(Step.Mileage);
                      }
                    } else {
                      setStep(Step.ArticleKey);
                    }
                  }}
                  placeholder={t('formFields', 'exact_model', 'placeholder')}
                  hint={t('formFields', 'exact_model', 'hint')}
                  tooltip={t('formFields', 'exact_model', 'tooltip')}
                />
              </Fade>
            )}
          </>
        ) : (
          <Fade className="mb-6">
            <Input
              label={t('formFields', 'license_plate_number', 'label')}
              hint={t('formFields', 'license_plate_number', 'hint')}
              tooltip={t('formFields', 'license_plate_number', 'tooltip')}
              placeholder={t(
                'formFields',
                'license_plate_number',
                'placeholder'
              )}
              type={getFormFieldType('license_plate_number')}
              maxLength={8}
              validator={(licensePlate) =>
                /^[A-Za-z]{2}\d{5,6}$/.test(licensePlate as string)
              }
              btnText={t('generalTextStrings', 'input_text_send_button')}
              showBtn={step === Step.LicensePlate}
              onChange={() =>
                step !== Step.LicensePlate ? setStep(Step.LicensePlate) : {}
              }
              onClick={(licensePlate) =>
                onGetVehicleByLicensePlate(licensePlate as string)
              }
            />
          </Fade>
        )}
      </div>

      {step >= Step.ArticleKey && (
        <div ref={articleKeyRef}>
          <ResultPane
            value={articleKey}
            options={formatRelevantVehicles(
              vehicleResults.all,
              { bodyType, fuel, gear, type },
              locale,
              attributes,
              t
            )}
            step={step}
            onChange={onChangeResultPane}
          />
        </div>
      )}

      <div className="mt-12 px-6">
        <span className="relative top-[-500px]" id="condition-section" />
        {step >= Step.Mileage && (
          <Fade className="mb-6">
            <Headline
              ref={conditionSectionRef}
              className="mb-5"
              content={t('directsaleTextStrings', 'condition_title')}
            />
            <Input
              ref={mileageRef}
              label={t('formFields', 'mileage', 'label')}
              hint={t('formFields', 'mileage', 'hint')}
              tooltip={t('formFields', 'mileage', 'tooltip')}
              placeholder={t('formFields', 'mileage', 'placeholder')}
              type={getFormFieldType('mileage')}
              maxLength={8}
              initialValue={mileage}
              inputMode="numeric"
              validator={(mileage) => Number.isFinite(mileage)}
              btnText={t('generalTextStrings', 'input_text_send_button')}
              showBtn={step === Step.Mileage}
              onChange={(value) =>
                step > Step.Mileage &&
                setUserInput((prev) => ({
                  ...prev,
                  mileage: parseNumber(value as string) as UserInput['mileage'],
                }))
              }
              onClick={(mileage) => {
                handleChangeUserInput('mileage', mileage);
                setStep(Step.Condition);
                posthog.capture('mileage_entered', {
                  brand: make,
                  model,
                  mileage,
                });
              }}
            />
          </Fade>
        )}

        {step >= Step.Condition && (
          <Fade className={selectCls}>
            <Select
              ref={conditionRef}
              label={t('formFields', 'condition', 'label')}
              options={getGeneralOptions('condition_options_')}
              value={condition}
              onChange={(value) => {
                if (step === Step.Condition) {
                  handleChangeUserInput('condition', value);
                  setStep(Step.Equipment);
                } else {
                  setUserInput((prev) => ({
                    ...prev,
                    condition: value as UserInput['condition'],
                  }));
                }
              }}
              placeholder={t('formFields', 'condition', 'placeholder')}
              hint={t('formFields', 'condition', 'hint')}
              tooltip={t('formFields', 'condition', 'tooltip')}
            />
          </Fade>
        )}

        {step >= Step.Equipment && (
          <Fade className={selectCls}>
            <Select
              ref={equipmentRef}
              label={t('formFields', 'equipment', 'label')}
              options={getGeneralOptions('equipment_options_')}
              value={equipment}
              onChange={(value) => {
                if (step === Step.Equipment) {
                  handleChangeUserInput('equipment', value);
                  setStep(Step.Email);
                } else {
                  setUserInput((prev) => ({
                    ...prev,
                    equipment: value as UserInput['equipment'],
                  }));
                }
              }}
              placeholder={t('formFields', 'equipment', 'placeholder')}
              hint={t('formFields', 'equipment', 'hint')}
              tooltip={t('formFields', 'equipment', 'tooltip')}
            />
          </Fade>
        )}

        {step >= Step.Email && (
          <Fade className="mt-12 mb-6">
            <span className="relative top-[-70px]" id="offer-section" />
            <Headline
              ref={offerSectionRef}
              className="mb-5"
              content={t('directsaleTextStrings', 'offer_form_title')}
            />
            <Input
              ref={emailRef}
              label={t('formFields', 'email', 'label')}
              hint={t('formFields', 'email', 'hint')}
              tooltip={t('formFields', 'email', 'tooltip')}
              placeholder={t('formFields', 'email', 'placeholder')}
              type={getFormFieldType('email')}
              initialValue={email}
              validator={(email) => validator.isEmail(email as string)}
              btnText={t('generalTextStrings', 'input_text_send_button')}
              btnHint={parse(t('generalTextStrings', 'submission_legal_hint'))}
              showBtn={step === Step.Email}
              onChange={(value) =>
                step > Step.Email &&
                setUserInput((prev) => ({
                  ...prev,
                  email: value as UserInput['email'],
                }))
              }
              onClick={(email) => {
                handleChangeUserInput('email', email);
                setStep(Step.FirstName);
                posthog.capture('email_entered');
              }}
            />
          </Fade>
        )}

        {step >= Step.FirstName && (
          <Fade className="mb-6">
            <Input
              ref={firstNameRef}
              label={t('formFields', 'first_name', 'label')}
              hint={t('formFields', 'first_name', 'hint')}
              tooltip={t('formFields', 'first_name', 'tooltip')}
              placeholder={t('formFields', 'first_name', 'placeholder')}
              type={getFormFieldType('first_name')}
              initialValue={firstName}
              btnText={t('generalTextStrings', 'input_text_send_button')}
              showBtn={step === Step.FirstName}
              onChange={(value) =>
                step > Step.FirstName &&
                setUserInput((prev) => ({
                  ...prev,
                  firstName: value as UserInput['firstName'],
                }))
              }
              onClick={(firstName) => {
                handleChangeUserInput('firstName', firstName);
                setStep(Step.LastName);
                posthog.capture('name_entered');
              }}
            />
          </Fade>
        )}

        {step >= Step.LastName && (
          <Fade className="mb-6">
            <Input
              ref={lastNameRef}
              label={t('formFields', 'last_name', 'label')}
              hint={t('formFields', 'last_name', 'hint')}
              tooltip={t('formFields', 'last_name', 'tooltip')}
              placeholder={t('formFields', 'last_name', 'placeholder')}
              type={getFormFieldType('last_name')}
              initialValue={lastName}
              btnText={t('generalTextStrings', 'input_text_send_button')}
              showBtn={step === Step.LastName}
              onChange={(value) =>
                step > Step.LastName &&
                setUserInput((prev) => ({
                  ...prev,
                  lastName: value as UserInput['lastName'],
                }))
              }
              onClick={(lastName) => {
                handleChangeUserInput('lastName', lastName);
                setStep(Step.Phone);
                posthog.capture('surname_entered');
              }}
            />
          </Fade>
        )}

        {step >= Step.Phone && (
          <Fade className="mb-6">
            <Input
              ref={phoneRef}
              label={t('formFields', 'phone', 'label')}
              hint={t('formFields', 'phone', 'hint')}
              tooltip={t('formFields', 'phone', 'tooltip')}
              placeholder={t('formFields', 'phone', 'placeholder')}
              type={getFormFieldType('phone')}
              initialValue={phone}
              validator={(phone) =>
                validator.isMobilePhone(
                  formatPhoneNumber(phone.toString()),
                  'any',
                  {
                    strictMode: true,
                  }
                )
              }
              btnText={t('generalTextStrings', 'input_text_send_button')}
              showBtn={step === Step.Phone}
              onChange={() => (step !== Step.Phone ? setStep(Step.Phone) : {})}
              onClick={(val) => onPhoneSubmit(val.toString())}
            />
          </Fade>
        )}

        {step >= Step.PhoneVerification && (
          <Fade className="mb-6">
            <PhoneVerification
              ref={otpCodeRef}
              verifyPhoneNumber={(code) => onVerifyPhoneNumber(code)}
              resendOtpCode={() => onSendOtpCode(userInput.phone)}
              onSuccess={(token) => {
                handleChangeUserInput('token', token);
                onSubmit({ verificationToken: token as string });
              }}
            />
          </Fade>
        )}
      </div>
    </>
  );
};

export default Main;
