import cx from 'classnames';

import { useCmsData } from 'contexts/CmsDataProvider';
import { scrollToSection, scrollToTop } from 'utils/utils';
import { Step } from 'enums/enums';

import { ReactComponent as MagnifierIcon } from 'assets/icons/magnifier.svg';
import { ReactComponent as VehicleIcon } from 'assets/icons/vehicle.svg';
import { ReactComponent as OfferIcon } from 'assets/icons/offer.svg';

export enum Stage {
  Search,
  Condition,
  Offer,
  Completed,
}

type Props = {
  className?: string;
  collapsed?: boolean;
  stage: Stage;
  step: Step;
  setStep: (step: Step) => void;
};

const StatusLine: React.FC<Props> = ({
  className,
  stage,
  collapsed = false,
  setStep,
  step,
}) => {
  const { t } = useCmsData();

  const stageCircleCls = (active: boolean) =>
    cx(
      'relative rounded-full bg-light p-3 cursor-pointer',
      !collapsed && active ? 'size-12' : 'size-6',
      active && 'bg-success'
    );
  const labelCls = (active: boolean) =>
    cx(
      'absolute left-1/2 -translate-x-1/2 mt-0.5 font-bold text-sm',
      active ? 'text-success' : 'text-border',
      collapsed && 'text-xs',
      !collapsed ? (active ? 'top-12' : 'top-9') : 'top-full'
    );

  return (
    <div
      className={cx(
        'relative flex items-center justify-between px-2.5',
        className
      )}
    >
      <div
        onClick={() => {
          if (step >= Step.Mileage && step < Step.FinalView) {
            scrollToTop();
          }
          if (step === Step.FinalView) {
            setStep(Step.Phone);
            scrollToTop();
          }
        }}
        className={stageCircleCls(stage === Stage.Search)}
      >
        {!collapsed && stage === Stage.Search && (
          <MagnifierIcon className="text-background" />
        )}
        <div className={labelCls(stage === Stage.Search)}>
          {t('directsaleTextStrings', 'step_search_title')}
        </div>
      </div>
      <div
        onClick={() => {
          if (step >= Step.Mileage && step < Step.FinalView) {
            scrollToSection('condition-section');
          }
          if (step === Step.FinalView) {
            setStep(Step.Phone);
            setTimeout(() => scrollToSection('condition-section'), 310);
          }
        }}
        className={cx(
          '!absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2',
          stageCircleCls(stage === Stage.Condition)
        )}
      >
        {!collapsed && stage === Stage.Condition && (
          <VehicleIcon className="text-background" />
        )}
        <div className={labelCls(stage === Stage.Condition)}>
          {t('directsaleTextStrings', 'step_condition_title')}
        </div>
      </div>
      <div
        className={stageCircleCls(
          stage === Stage.Offer || stage === Stage.Completed
        )}
        onClick={() => {
          if (step >= Step.Email && step < Step.FinalView) {
            scrollToSection('offer-section');
          }
        }}
      >
        {(!collapsed && stage === Stage.Offer) ||
          (stage === Stage.Completed && (
            <OfferIcon className="text-background" />
          ))}
        <div
          className={labelCls(
            stage === Stage.Offer || stage === Stage.Completed
          )}
        >
          {t('directsaleTextStrings', 'step_offer_title')}
        </div>
      </div>
      <div className="absolute w-[calc(100%-20px)] h-[1px] bg-light z-[-1]" />
    </div>
  );
};

export default StatusLine;
